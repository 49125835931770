import { createGlobalStyle } from "styled-components";
import SlashSvg from "../images/icons/slash.svg";

const GlobalStyles = createGlobalStyle`

  html,
  body,
  #root {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    min-height: 100%;
  }

  body {
    width: 100%;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.5;
    color: ${props => props.theme.colors.black.off};
    -webkit-font-smoothing: antialiased;

    @media (min-width: ${props => props.theme.grid.breakpointTablet}px) {
      font-size: 16px;
    }

    * {
      box-sizing: border-box;

      &::selection {
        background: ${props => props.theme.colors.black.off};
        color: white;
      }
    }
  }

  /*
  A workaround for forcing accessibility wrappers
  to have a 100% height.
  Reach Router issue here: https: //github.com/reach/router/issues/63
  */
  #___gatsby,
  div[role="group"][tabindex] {
    height: 100%;
    min-height: 100% !important;
  }

  img {
    max-width: 100%;
  }

  ul, ol {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
  }

  button {
    &:focus {
      outline: none;
    }
    &:active {
      border-style: none;
    }
  }

  .slash-before {
    position:relative;
    margin-left: 20px;
    list-style: none;

    &::before {
      display: inline-block;
      content: "";
      left: -15px;
      top: -20px;
      position: absolute;
      height: 21px;
      width: 3px;
      transform: skew(-33deg);
      background-color: ${props => props.theme.colors.turquoise.main};
      overflow: hidden;
    }
  }

  .slash-after {
    position:relative;

    &::after {
      content: url(${SlashSvg});
      position: absolute;
      margin-left: 20px;
      margin-top: 5px;
      transform: rotate(-5deg);
    }
  }

  .mobile-nav-open {
    height: 100%;
    overflow: hidden;
  }
`;

export default GlobalStyles;
