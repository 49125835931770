import React from "react";
import styled from "styled-components";

const Errors = styled.ul`
  list-style: none outside;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-left: 0;
  padding-inline-start: 0;
`;
const Error = styled.li`
  color: ${props => props.theme.colors.red.main};
  font-size: ${props => props.theme.type.sizes.zelta.desktop};
  font-weight: ${props => props.theme.type.weights.normal};
  line-height: ${props => props.theme.type.sizes.zeltaSemi.desktop};
  margin-top: 8px;
  transition: ${props => props.theme.transition.base};
`;

const ErrorMessage = ({ children }) => (
  <Errors>
    <Error>{children}</Error>
  </Errors>
);

export default ErrorMessage;
