//theme colors and tints (dark900 to normal500 to light100)
const colors = {
  blue: {
    main: "#1445F2",
    dark: "#0C31B4",
    darker: "#06289F",
    seeThrough: "rgba(0,0,0,0.5)",
  },

  black: {
    main: "#000000",
    text: "#222",
    off: "#333333",
  },

  white: {
    main: "#ffffff",
    smoke: "#f5f5f5",
    seeThrough: "rgba(250,250,250,0.8)",
  },

  grey: {
    main: "#868686",
    light: "#ECECEC",
    dark: "#333333",
    darker: "#2f2f2f", //sub menu tab not hovered
    seeThrough: "rgba(0,0,0,0.7)",
  },

  turquoise: {
    main: "#1DC0DA",
  },

  yellow: {
    main: "#EBC01F",
  },

  red: {
    main: "#DB3939",
  },

  gradients: {
    redBlack: "linear-gradient(212.47deg, #FC333F -2.03%, #170204 95.6%)",
  },

  boxShadow: {
    main: "0 8px 10px rgba(0, 0, 0, 0.225)",
    slight: "0 4px 8px rgba(0, 0, 0, 0.25)",
  },
};

export default colors;
