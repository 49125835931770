import { createGlobalStyle, css } from "styled-components";
import grid from "./grid";

const baseH6 = css`
  font-size: 13px;
  line-height: 1.1;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;

  @media (min-width: ${grid.breakpointTablet}px) {
    font-size: 14px;
  }

  @media (min-width: ${grid.breakpointDesktop}px) {
    font-size: 14px;
  }
`;

const typeStyles = createGlobalStyle`

  html {
    font-family: "sofia-pro", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  h1 {
    font-size: 32px;
    line-height: 1.1;
    font-weight: 700;
    margin: 0;

    @media (min-width: ${props => props.theme.grid.breakpointTablet}px) {
      font-size: 46px;
      letter-spacing: -1px;
    }

    @media (min-width: ${props => props.theme.grid.breakpointDesktop}px) {
      font-size: 72px;
      letter-spacing: -1.5px;
    }
  }

  h2 {
    font-size: 32px;
    line-height: 1.2;
    font-weight: 700;
    margin: 0;

    @media (min-width: ${props => props.theme.grid.breakpointTablet}px) {
      font-size: 40px;
    }

    @media (min-width: ${props => props.theme.grid.breakpointDesktop}px) {
      font-size: 48px;
      letter-spacing: -1px;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 1.3;
    font-weight: 700;
    margin: 0;

    @media (min-width: ${props => props.theme.grid.breakpointTablet}px) {
      font-size: 28px;
    }

    @media (min-width: ${props => props.theme.grid.breakpointDesktop}px) {
      font-size: 32px;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 700;
    margin: 0;

    @media (min-width: ${props => props.theme.grid.breakpointTablet}px) {
      font-size: 20px;
    }

    @media (min-width: ${props => props.theme.grid.breakpointDesktop}px) {
      font-size: 24px;
    }
  }

  h5 {
    margin: 0;
    font-weight: 500;
    line-height: 20px;
    font-size: 0.95em;
  }

  h6 {
    ${baseH6};
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    margin: 0;

    @media (min-width: ${props => props.theme.grid.breakpointTablet}px) {
      font-size: 17px;
    }

    @media (min-width: ${props => props.theme.grid.breakpointDesktop}px) {
      font-size: 18px;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }

  button {
    font-family: "sofia-pro", "Helvetica Neue", Arial, sans-serif;
  }

  button.tab {
    padding: 0 0 4px 0;
    background: none;
    border: none;
    font-size: 1rem;
    font-weight: normal;
    color: ${props => props.theme.colors.grey.main};

    &.selected {
      font-weight: bold;
      border-bottom: 3px solid ${props => props.theme.colors.black.main};
      color: ${props => props.theme.colors.black.main};
    }
  }
`;

export default typeStyles;
export { baseH6 };
