import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";

import GlobalStyles from "../../styles/global";
import TypeStyles from "../../styles/typography";

import theme from "../../styles/theme";

import "../../styles/font.css";
import "../../styles/overrides.css";

const Layout = ({ children, path, isModal }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <TypeStyles />
    <main>{children}</main>
    <div id="modal-node"></div>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string,
};

export default Layout;
