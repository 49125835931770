import React from "react";
import styled from "styled-components";

import Layout from "components/layout/Layout";
import LoginForm from "../components/LoginForm";

const Wrapper = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 10px;

  @media (min-width: ${props => props.theme.grid.breakpointTablet}px) {
    max-width: 480px;
    margin: auto;
  }
`;

const Login = ({ content, meta }) => (
  <>
    <Layout>
      <Wrapper id="login-form">
        <LoginForm />
      </Wrapper>
    </Layout>
  </>
);

export default Login;
