import React from "react";
import { Field } from "react-final-form";

import ButtonPrimary from "../ui/ButtonPrimary";
import InputTextField from "./InputTextField";
import InputWrapper from "./InputWrapper";
import { required } from "./validation";

const FormComponent = ({ handleSubmit, formSuccess = false, submitting }) => {
  return (
    <div>
      {formSuccess ? (
        <h1>Success</h1>
      ) : (
        <form onSubmit={handleSubmit}>
          <Field
            component={InputTextField}
            id="username"
            label="Username"
            name="username"
            type="test"
            validate={required}
          />

          <Field
            component={InputTextField}
            id="password"
            label="Password"
            name="password"
            type="password"
            validate={required}
          />

          <InputWrapper>
            <ButtonPrimary type="submit" disabled={submitting || formSuccess}>
              Submit
            </ButtonPrimary>
          </InputWrapper>
        </form>
      )}
    </div>
  );
};

export default FormComponent;
