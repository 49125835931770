import React, { useState, useEffect } from "react";
import { Form as FinalForm } from "react-final-form";

import Form from "./Form";
import parseAPIErrors from "./parseAPIErrors";
import request from "./request";
import { setCookie, getCookie } from "../../utils/cookies";

const COOKIE_AUTH = "auth";

const LoginForm = () => {
  const [apiConfig, setAPIConfig] = useState(null);
  const [authToken, setAuthToken] = useState(null);

  const postFormSubmit = async values => {
    const { loginAPI } = apiConfig;
    const options = {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await request(loginAPI, options);
      const { auth } = response;
      setAuthToken(auth);
    } catch (error) {
      return parseAPIErrors(error);
    }
  };

  const getAPIConfig = async () => {
    try {
      const response = await request("/config-login.json");
      setAPIConfig(response);
    } catch (error) {
      console.log("config", error);
    }
  };

  const redirectUserToSite = () => {
    window.location.replace("/");
  };

  useEffect(() => {
    if (authToken !== null) {
      setCookie(COOKIE_AUTH, authToken, 7);
      redirectUserToSite();
    }
  }, [authToken]);

  useEffect(() => {
    if (getCookie(COOKIE_AUTH)) {
      redirectUserToSite();
      return;
    }

    if (!apiConfig) {
      getAPIConfig();
    }
  }, []);

  return (
    <>
      {apiConfig && (
        <FinalForm
          onSubmit={postFormSubmit}
          component={Form}
        />
      )}
    </>
  );
};

export default LoginForm;
