import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const ButtonContainer = styled.button`
  background-color: ${props =>
    props.colour === "white"
      ? `${props.theme.colors.white.main}`
      : `${props.theme.colors.blue.main}`};
  color: ${props =>
    props.colour === "white"
      ? `${props.theme.colors.blue.main}`
      : `${props.theme.colors.white.main}`};
  font-size: 18px;
  font-weight: 600;
  padding: 14px 24px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  text-align: center;
  max-width: fit-content;
  outline: none;
  border: none;
  border-radius: 0;
  position: relative;
  white-space: nowrap;
  transition: background 100ms ease-in-out;
  line-height: 1;

  @media (min-width: ${props => props.theme.grid.breakpointDesktop}px) {
    letter-spacing: 0.4px;
    font-size: 22px;
    padding: 16px 32px;
  }

  &:hover {
    cursor: pointer;
    transition: background 100ms ease-in-out;
    background-color: ${props =>
      props.colour === "white"
        ? `${props.theme.colors.white.seeThrough}`
        : `${props.theme.colors.blue.dark}`};
  }

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: ${props => props.theme.colors.grey.main};
      :hover {
        cursor: not-allowed;
        background-color: ${props => props.theme.colors.grey.main};
      }
    `}
`;

const ButtonPrimary = ({ size, colour, children, disabled = false }) => (
  <ButtonContainer size={size} colour={colour} disabled={disabled}>
    {children}
  </ButtonContainer>
);

ButtonPrimary.propTypes = {
  size: PropTypes.oneOf(["large", "small"]),
  colour: PropTypes.oneOf(["white", "blue"]),
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

ButtonPrimary.defaultProps = {
  size: "large",
  colour: "blue",
};

export default ButtonPrimary;
