import colors from "./colors";
import spacing from "./spacing";
import grid from "./grid";

const getRems = pxValue => pxValue / 16;

const theme = {
  colors,

  spacing,

  grid,

  flexboxgrid: {
    // 12-column grid settings
    gridSize: 12, // columns
    gutterWidth: getRems(grid.gutterMobile),
    outerMargin: getRems(grid.outerGutterMobile),
    mediaQuery: "only screen",
    container: {
      // max-width
      sm: getRems(grid.maxwidthMobile),
      md: getRems(grid.maxwidthTablet),
      lg: getRems(grid.maxwidthDesktop),
    },
    breakpoints: {
      xs: 0,
      sm: getRems(grid.breakpointMobile),
      md: getRems(grid.breakpointTablet),
      lg: getRems(grid.breakpointDesktop),
    },
  },

  transition: {
    base: "all 0.2s ease",
  },

  type: {
    sizes: {
      giga: {
        desktop: "72px",
        mobile: "40px",
      },
      alpha: {
        desktop: "48px",
        mobile: "32px",
      },
      betaLarge: {
        desktop: "32px",
        mobile: "24px",
      },
      beta: {
        desktop: "24px",
        mobile: "18px",
      },
      delta: {
        desktop: "18px",
        mobile: "16px",
      },
      zeltaSemi: {
        desktop: "14px",
        mobile: "16px",
      },
      zeltaEx: {
        desktop: "14px",
        mobile: "16px",
      },
      zelta: {
        desktop: "14px",
        mobile: "12px",
      },
    },
    lineHeights: {
      giga: {
        desktop: "80px",
        mobile: "48px",
      },
      alpha: {
        desktop: "56px",
        mobile: "40px",
      },
      betaLarge: {
        desktop: "40px",
        mobile: "30px",
      },
      beta: {
        desktop: "32px",
        mobile: "24px",
      },
      delta: {
        desktop: "24px",
        mobile: "24px",
      },
      zeltaSemi: {
        desktop: "16px",
        mobile: "18px",
      },
      zeltaEx: {
        desktop: "16px",
        mobile: "24px",
      },
      zelta: {
        desktop: "14px",
        mobile: "14px",
      },
    },
    weights: {
      normal: 400,
      bold: 700,
    },
  },
};

export default theme;
