//grid for device media queries and layout padding
const grid = {
  //pixels
  breakpointMobile: 480,
  breakpointTablet: 768,
  breakpointDesktop: 1200,
  breakpointLargeDesktop: 1440,

  //pixels
  maxwidthMobile: 440,
  maxwidthTablet: 728,
  maxwidthDesktop: 1160,
  maxwidthLargeDesktop: 1380,

  //px
  outerGutterMobile: 20,
  // outerGutterTablet: 20,
  // outerGutterDesktop: 20,

  //px
  gutterMobile: 30,
  // gutterTablet: 20,
  // gutterDesktop: 30,
};

export default grid;
