import React from "react";
import styled, { css } from "styled-components";

import ErrorMessage from "./ErrorMessage";
import Label from "./Label";
import InputWrapper from "./InputWrapper";

const Input = styled.input`
  box-sizing: border-box;
  background-clip: padding-box;
  background-color: ${props => props.theme.colors.white.smoke};
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid ${props => props.theme.colors.grey.main};
  caret-color: ${props => props.theme.colors.black.text};
  color: ${props => props.theme.colors.black.text};
  display: block;
  font-size: ${props => props.theme.type.sizes.delta.mobile};
  height: 34px;
  width: 100%;
  line-height: 1.25;
  padding: 12px;
  transition: ${props => props.theme.transition.base};

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${props => props.theme.colors.grey.main};
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${props => props.theme.colors.grey.main};
  }

  :disabled {
    color: ${props => props.theme.colors.grey.main};
    border: 1px solid ${props => props.theme.colors.grey.main};
    background-color: ${props => props.theme.colors.grey.light};
  }

  :focus {
    background-color: ${props => props.theme.colors.white.main};
    color: ${props => props.theme.colors.black.main};
    border-bottom: 1px solid ${props => props.theme.colors.black.main};
    box-shadow: none;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${props => props.theme.colors.grey.main};
  }

  @media (min-width: ${props => props.theme.grid.breakpointTablet}px) {
    font-size: ${props => props.theme.type.sizes.delta.desktop};
    height: 44px;
  }

  ${props =>
    props.error &&
    css`
      border-bottom: 1px solid ${props => props.theme.colors.red.main};
      color: ${props => props.theme.colors.red.main};
    `}
`;

const InputTextField = ({
  autoComplete = "on",
  disabled,
  id,
  input,
  inputmode,
  label,
  meta,
  onChange,
  pattern,
  placeholder,
  type = "text",
}) => {
  const hasError = (meta.error || meta.submitError) && meta.touched;

  return (
    <InputWrapper>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Input
        autoComplete={autoComplete}
        disabled={disabled}
        error={hasError}
        id={id}
        inputmode={inputmode}
        onChange={onChange}
        pattern={pattern}
        placeholder={placeholder}
        type={type}
        {...input}
      />
      {hasError && (
        <ErrorMessage>{meta.error || meta.submitError}</ErrorMessage>
      )}
    </InputWrapper>
  );
};

export default InputTextField;
