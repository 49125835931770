const parseJSON = response => {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
};

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  throw response;
};

const handleError = async response => {
  const error = await response.text().then(text => text);
  return Promise.reject(JSON.parse(error));
};

const request = (url, options) => {
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .catch(handleError);
};

export default request;
