const parseAPIErrors = error => {
  const message = error.error.message;

  if (error && message && typeof message === "object") {
    return Object.keys(message).reduce((res, fieldName) => {
      const errorKey =
        fieldName.charAt(0).toLowerCase() + fieldName.substring(1);
      if (!res[errorKey]) {
        res[errorKey] = message[fieldName];
        if (typeof res[errorKey] === "object") {
          res[errorKey] = Object.keys(res[errKey]).reduce(
            (result, key) => `${result}, ${res[errKey][key]}`,
            ""
          );
        }
      }
      return res;
    }, {});
  }

  return {};
};

export default parseAPIErrors;
