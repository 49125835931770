import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 24px;

  &:first-child {
    margin-top: 0;
  }
`;

const InputWrapper = ({ children, className }) => (
  <Wrapper className={className}>
    {children}
  </Wrapper>
);

export default InputWrapper;
