import React from "react";
import styled from "styled-components";

const Label = styled.label`
  display: block;
  color: ${props => props.theme.colors.black.text};
  font-size: ${props => props.theme.type.sizes.zelta.desktop};
  margin-bottom: 4px;
`;

const InputLabel = ({ className, htmlFor, children, as }) => (
  <Label as={as} className={className} htmlFor={htmlFor}>
    {children}
  </Label>
);

export default InputLabel;
